import React, { Component } from 'react';
import { patchVehicle } from '../../api';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { DropdownList } from 'react-widgets';
import { clearVehicle, getVehicleByName, createVehicleDamage } from '../../state/vehicle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ReportScooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: 'pickup_requested',
      feedback: '',
      feedbackColor: 'red',
      disabled: false,
      name: '',
    };
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeReportType = (event) => {
    this.setState({
      reportType: event.id,
    });
  };

  onSubmitVehicle = (event) => {
    this.setState({ feedback: '', disabled: false });
    event.preventDefault();
    if (this.state.name === '') {
      this.setState({ feedback: 'Please enter a scooter name', feedbackColor: 'red' });
    } else {
      this.props.actions
        .getVehicleByName(this.state.name.toUpperCase())
        .then((vehicle) => {
          const hash = this.props.vehicle.hash;
          const report_type = this.state.reportType;
          this.setState({
            vehicle,
            disabled: false,
          });
          if (report_type === 'pickup_requested') {
            const params = { status: report_type };
            patchVehicle(this.props.vehicle.hash, params)
              .then((json) => {
                this.setState({
                  feedback: 'Scooter updated successfully!',
                  feedbackColor: 'green',
                });
              })
              .catch((error) => {
                this.setState({
                  feedback: error.message || 'An unknown error occurred',
                  feedbackColor: 'red',
                  disabled: false,
                });
              });
          } else {
            const params = [{ name: this.state.reportType, vehicle_hash: hash }];
            this.props.actions
              .createVehicleDamage(params)
              .then((json) => {
                this.setState({
                  feedback: 'Scooter updated successfully!',
                  feedbackColor: 'green',
                });
              })
              .catch((error) => {
                this.setState({
                  feedback: error.message || 'An unknown error occurred',
                  feedbackColor: 'red',
                  disabled: false,
                });
              });
          }
        })
        .catch((error) => {
          this.setState({
            feedback: error.message || 'An unknown error occurred',
            feedbackColor: 'red',
            disabled: false,
          });
        });
    }
  };

  onClickReset = (event) => {
    event.preventDefault();
    this.setState({ name: '' });
    this.props.actions.clearVehicle();
  };

  render() {
    const { name } = this.state;
    const report_type_values = [
      { id: 'pickup_requested', name: 'Pickup Requested' },
      { id: 'basket', name: 'Basket Issue' },
      { id: 'brake_cable', name: 'Brake Issue' },
      { id: 'fender_front', name: 'Fender (Front) Issue' },
      { id: 'fender_rear', name: 'Fender (Rear) Issue' },
      { id: 'headlight', name: 'Headlight Issue' },
      { id: 'kickstand', name: 'Kickstand Issue' },
      { id: 'seat', name: 'Seat Issue' },
      { id: 'wheel_front', name: 'Wheel (Front) Issue' },
      { id: 'wheel_rear', name: 'Wheel (Rear) Issue' },
    ];
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <div>
          <p style={{ color: this.state.feedbackColor }}>{this.state.feedback}</p>
        </div>
        <div className="page-title">Open a Maintenance Ticket on a Vehicle</div>
        <form onSubmit={this.onSubmitVehicle}>
          <div className="form_input_section">
            <label className="form_input_section__label" htmlFor="name">
              Scooter Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={this.onChange}
            />
          </div>
          <div className="form_input_section">
            <label className="form_input_section__label" htmlFor="batteryType">
              Report Type
            </label>
            <DropdownList
              className="edit_user__pricing_plan_selector"
              data={report_type_values}
              valueField="id"
              textField="name"
              onChange={this.handleChangeReportType}
              placeholder="Select Report Type"
            />
          </div>
          <div className="form_input_section">
            <input type="submit" disabled={this.state.disabled} />
            <button onClick={this.onClickReset}>Reset</button>
          </div>
        </form>
      </AuthenticatedPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { vehicle } = state;
  return {
    vehicle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ clearVehicle, getVehicleByName, createVehicleDamage }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportScooter);
